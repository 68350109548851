/* React Imports */
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from './context/AuthProvider'; 
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

/* AntD Theme */
import { ConfigProvider } from "antd";

datadogRum.init({
  applicationId: '9d6f857b-efef-4e3c-b889-4173c26da785',
  clientToken: 'pub42ca38d33e3bfb75809141d6b5282ad9',
  technical_applicationid: 'APP-002845',
  site: 'datadoghq.com',
  service:'wmt-barcode-generator',
  env:'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input'
});

datadogRum.setGlobalContextProperty('technical_applicationid','APP-002845');
datadogRum.setGlobalContextProperty('service', 'wmt-barcode-generator');
datadogRum.setGlobalContextProperty('version', '1.0');
datadogRum.setGlobalContextProperty('env', 'prod');

datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4AB3E9",
        },
      }}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path='/*' element={<App />}/>          
            </Routes>
          </AuthProvider>
        </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>
);
